
    /*****語言下拉選單****************/
    $(".mobile-languageBtn").click(function(){
        $box = $("#myNavbar");
        $body = $("body");
        $time = 300;
        $box.addClass('in');
        // reset height and revert to original if current and auto are equal
        //- $box.css('height', autoHeight).animate({
        //-     height: (autoHeight == minimumHeight ? minimumHeight : autoHeight)
        //- })
        if($box.css('height') > '1px') {
            $box.animate({height : '0px'}, $time);
            setTimeout(function () {
              $box.removeClass('in');
              $body.css('overflow', 'auto');
            }, $time*2);

        }
        else {
            $box.animate({height : '100%'},  $time);
            $body.css('overflow', 'hidden');
        }
    });
